// @ts-nocheck
import React from 'react';
import { ApplyPluginsType, dynamic } from '/Users/craig/Desktop/wqy/pngtree/seapik-editor/pikbest_image/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';
import LoadingComponent from '@/pages/Loading';

export function getRoutes() {
  const routes = [
  {
    "path": "/",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__index' */'@/pages/index'), loading: LoadingComponent}),
    "exact": true
  },
  {
    "path": "/designer.html",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__designer__index' */'@/pages/designer/index'), loading: LoadingComponent}),
    "title": "Seapik 模板审核",
    "exact": true
  },
  {
    "path": "/test.html",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__index' */'@/pages/index'), loading: LoadingComponent}),
    "title": "Seapik 测试页面",
    "exact": true
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
