import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

export default () => (
  <Spin
    spinning={true}
    size="large"
    tip="Loading..."
    indicator={
      <div
        style={{
          fontSize: 60,
          marginTop: -80,
          transform: 'translateX(-50%)',
        }}
      >
        <LoadingOutlined />
      </div>
    }
  >
    <div style={{ height: '100vh', width: '100vw' }}></div>
  </Spin>
);
